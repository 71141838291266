import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Frontpage from "./pages/Frontpage";

import "antd/dist/antd.css";

const App = () => {
  return (
    <>
      {/* <Header></Header> */}

      <div style={{ minHeight: "calc(100vh - 135px)" }}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Frontpage} />
          </Switch>
        </BrowserRouter>
      </div>

      {/* <Footer></Footer> */}
    </>
  );
};

export default App;
