import React, { useEffect } from "react";
import HeroSection from "../components/HeroSection";

export default function Frontpage() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.seated.com/app.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <HeroSection vheight={100} id="frontMain">
        <>
          <div className="main_cards_frontpage">
            <div
              className=""
              style={{ background: "rgba(0,0,0,0.7)", marginTop: 20 }}
            >
              <div
                id="seated-55fdf2c0"
                sytle={{}}
                data-artist-id="dde731e7-9e4b-4334-a4dd-ba7d0d9f202e"
                data-css-version="2"
              ></div>
            </div>

            {/* <Table
              className="venueTable"
              pagination={false}
              dataSource={tourList.sort((a, b) => {
                return a.attributes["starts-at"] < b.attributes["starts-at"]
                  ? -1
                  : 1;
              })}
              rowKey="id"
              columns={[
                {
                  title: "Venue / Date",
                  render: (item) => {
                    const attrs = item.attributes;
                    return (
                      <a
                        href={`https://link.seated.com/${item.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div style={{ marginBottom: 5 }}>
                          {attrs["is-sold-out"] && (
                            <span
                              style={{
                                background: "red",
                                padding: "5px",
                                color: "white",
                                borderRadius: "5px",
                                lineHeight: "10px",
                                display: "inline-block",
                              }}
                            >
                              Sold out
                            </span>
                          )}{" "}
                          {attrs["formatted-address"]}
                        </div>
                        <div style={{ color: "gray" }}>
                          {attrs["starts-at-short"]}
                        </div>
                      </a>
                    );
                  },
                },
                {
                  title: "Location",
                  render: (item) => {
                    const attrs = item.attributes;
                    return (
                      <a
                        href={`https://link.seated.com/${item.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>{attrs["venue-name"]}</div>
                        <div>{attrs["formatted-address"]}</div>
                      </a>
                    );
                  },
                },
              ]}
            ></Table> */}
          </div>
        </>
      </HeroSection>
    </>
  );
}
